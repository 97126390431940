import React from "react"

import "@fontsource/merriweather-sans"
import "@fontsource/montserrat"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import StarsBackground from "../components/stars-bg"

const IndexPage = () => (
  <>
      <StarsBackground />
      <Layout>
        <SEO />
        <Hero />
      </Layout>
  </>
)

export default IndexPage
